import React from "react";
import { Routes, Route } from "react-router-dom";
import Auth from "features/users/pages/Auth/Auth";
import Homepage from "pages/Homepage/Homepage";
import { useAuth } from 'hooks/auth-hook';

const NotFound = React.lazy(() => import('pages/NotFound/NotFound'));
const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'));
const SearchResults = React.lazy(() => import('pages/SearchResults/SearchResults'));
const Users = React.lazy(() => import('features/users/pages/Users/Users'));

const Days = React.lazy(() => import('features/days/pages/Days/Days'));
const CreateDay = React.lazy(() => import('features/days/pages/CreateDay/CreateDay'));
const EditDay = React.lazy(() => import('features/days/pages/EditDay/EditDay'));
const ViewDay = React.lazy(() => import('features/days/pages/ViewDay/ViewDay'));

const Notes = React.lazy(() => import('features/notes/pages/Notes/Notes'));
const CreateNote = React.lazy(() => import('features/notes/pages/CreateNote/CreateNote'));
const EditNote = React.lazy(() => import('features/notes/pages/EditNote/EditNote'));
const ViewNote = React.lazy(() => import('features/notes/pages/ViewNote/ViewNote'));

const Phrases = React.lazy(() => import('features/phrases/pages/Phrases/Phrases'));
const CreatePhrase = React.lazy(() => import('features/phrases/pages/CreatePhrase/CreatePhrase'));
const EditPhrase = React.lazy(() => import('features/phrases/pages/EditPhrase/EditPhrase'));
const ViewPhrase = React.lazy(() => import('features/phrases/pages/ViewPhrase/ViewPhrase'));

const Maxims = React.lazy(() => import('features/maxims/pages/Maxims/Maxims'));
const CreateMaxim = React.lazy(() => import('features/maxims/pages/CreateMaxim/CreateMaxim'));
const EditMaxim = React.lazy(() => import('features/maxims/pages/EditMaxim/EditMaxim'));
const ViewMaxim = React.lazy(() => import('features/maxims/pages/ViewMaxim/ViewMaxim'));

const FlashCards = React.lazy(() => import('features/flash-cards/pages/FlashCards/FlashCards'));
const CreateFlashCard = React.lazy(() => import('features/flash-cards/pages/CreateFlashCard/CreateFlashCard'));
const EditFlashCard = React.lazy(() => import('features/flash-cards/pages/EditFlashCard/EditFlashCard'));
const ViewFlashCard = React.lazy(() => import('features/flash-cards/pages/ViewFlashCard/ViewFlashCard'));
const FlashCardsGame = React.lazy(() => import('features/flash-cards/pages/FlashCardsGame/FlashCardsGame'));

const ViewPage = React.lazy(() => import('features/pages/pages/ViewPage/ViewPage'));
const CreatePage = React.lazy(() => import('features/pages/pages/CreatePage/CreatePage'));
const EditPage = React.lazy(() => import('features/pages/pages/EditPage/EditPage'));

const Tasks = React.lazy(() => import('features/tasks/pages/Tasks/Tasks'));
const CreateTask = React.lazy(() => import('features/tasks/pages/CreateTask/CreateTask'));
const EditTask = React.lazy(() => import('features/tasks/pages/EditTask/EditTask'));
const ViewTask = React.lazy(() => import('features/tasks/pages/ViewTask/ViewTask'));

const Wallpapers = React.lazy(() => import('features/wallpapers/pages/Wallpapers/Wallpapers'));
const CreateWallpaper = React.lazy(() => import('features/wallpapers/pages/CreateWallpaper/CreateWallpaper'));
const EditWallpaper = React.lazy(() => import('features/wallpapers/pages/EditWallpaper/EditWallpaper'));
const ViewWallpaper = React.lazy(() => import('features/wallpapers/pages/ViewWallpaper/ViewWallpaper'));

const AppRoutes = (props) => {
  let routes;
  const { token } = useAuth();
  const jesseAccountPublicViewMode = localStorage.getItem('jesseAccountPublicViewMode');

  if (token && jesseAccountPublicViewMode === 'private') {
    routes = (
      <Routes>
        <Route path="/" element={<Homepage jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/dashboard" element={<Dashboard jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/search/:userId/:query" element={<SearchResults jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/tag/:userId/:query" element={<SearchResults searchTagsOnly jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/:userId/days" element={<Days jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/days/:date" element={<ViewDay />} />
        <Route path="/days/create" element={<CreateDay />} />
        <Route path="/days/create/:date" element={<CreateDay />} />
        <Route path="/days/edit/:date" element={<EditDay />} />
        <Route path="/:userId/flash-cards" element={<FlashCards jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/:userId/flash-cards-game" element={<FlashCardsGame jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/flash-cards/:id" element={<ViewFlashCard />} />
        <Route path="/flash-cards/create" element={<CreateFlashCard />} />
        <Route path="/flash-cards/edit/:id" element={<EditFlashCard />} />
        <Route path="/:userId/phrases" element={<Phrases jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/phrases/:id" element={<ViewPhrase />} />
        <Route path="/phrases/create" element={<CreatePhrase />} />
        <Route path="/phrases/edit/:id" element={<EditPhrase />} />
        <Route path="/:userId/maxims" element={<Maxims jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/maxims/:id" element={<ViewMaxim />} />
        <Route path="/maxims/create" element={<CreateMaxim />} />
        <Route path="/maxims/edit/:id" element={<EditMaxim />} />
        <Route path="/:userId/notes" element={<Notes jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/notes/:id" element={<ViewNote />} />
        <Route path="/notes/create" element={<CreateNote />} />
        <Route path="/notes/edit/:id" element={<EditNote />} />
        <Route path="/pages/create" element={<CreatePage />} />
        <Route path="/:userId/tasks" element={<Tasks jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/tasks/:id" element={<ViewTask />} />
        <Route path="/tasks/create" element={<CreateTask />} />
        <Route path="/tasks/edit/:id" element={<EditTask />} />
        <Route path="/:userId/wallpapers" element={<Wallpapers jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/wallpapers/preview/:id" element={<ViewWallpaper />} />
        <Route path="/wallpapers/create" element={<CreateWallpaper />} />
        <Route path="/wallpapers/edit/:id" element={<EditWallpaper />} />
        <Route path="/users" element={<Users />} />
        <Route path='*' element={<NotFound />} />

        {/* Create user defined 'View' page routes */}
        {props.userCreatedPages.map(page => (
          <Route key={page.id} path={`${page.pageParent.toLowerCase()}/:id`} element={<ViewPage />} />
        ))}

        {/* Create user defined 'Edit' page routes */}
        {props.userCreatedPages.map(page => (
          <Route key={page.id} path={`${page.pageParent.toLowerCase()}/edit/:id`} element={<EditPage />} />
        ))}
      </Routes>
    );
  } else if (token && jesseAccountPublicViewMode === 'public') {
    routes = (
      <Routes>
        <Route path="/" element={<Homepage jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/search/:userId/:query" element={<SearchResults jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/tag/:userId/:query" element={<SearchResults searchTagsOnly jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/:userId/flash-cards" element={<FlashCards jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/:userId/flash-cards-game" element={<FlashCardsGame jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/flash-cards/:id" element={<ViewFlashCard />} />
        <Route path="/flash-cards/create" element={<CreateFlashCard />} />
        <Route path="/flash-cards/edit/:id" element={<EditFlashCard />} />
        <Route path="/:userId/notes" element={<Notes jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/notes/:id" element={<ViewNote />} />
        <Route path="/notes/create" element={<CreateNote />} />
        <Route path="/notes/edit/:id" element={<EditNote />} />
        <Route path="/pages/create" element={<CreatePage />} />
        <Route path="/:userId/tasks" element={<Tasks jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/tasks/:id" element={<ViewTask />} />
        <Route path="/tasks/create" element={<CreateTask />} />
        <Route path="/tasks/edit/:id" element={<EditTask />} />
        <Route path="/:userId/wallpapers" element={<Wallpapers jesseAccountPublicViewMode={jesseAccountPublicViewMode} />} />
        <Route path="/wallpapers/preview/:id" element={<ViewWallpaper />} />
        <Route path="/wallpapers/create" element={<CreateWallpaper />} />
        <Route path="/wallpapers/edit/:id" element={<EditWallpaper />} />
        <Route path="/users" element={<Users />} />
        <Route path='*' element={<NotFound />} />

        {/* Create user defined 'View' page routes */}
        {props.userCreatedPages.map(page => (
          <Route key={page.id} path={`${page.pageParent.toLowerCase()}/:id`} element={<ViewPage />} />
        ))}

        {/* Create user defined 'Edit' page routes */}
        {props.userCreatedPages.map(page => (
          <Route key={page.id} path={`${page.pageParent.toLowerCase()}/edit/:id`} element={<EditPage />} />
        ))}
      </Routes>
    );
  } else if (token) {
    routes = (
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/notes/:id" element={<ViewNote />} />
        <Route path="/search/:userId/:query" element={<SearchResults />} />
        <Route path="/tag/:userId/:query" element={<SearchResults searchTagsOnly />} />
        <Route path="/:userId/flash-cards" element={<FlashCards />} />
        <Route path="/:userId/flash-cards-game" element={<FlashCardsGame />} />
        <Route path="/flash-cards/:id" element={<ViewFlashCard />} />
        <Route path="/flash-cards/create" element={<CreateFlashCard />} />
        <Route path="/flash-cards/edit/:id" element={<EditFlashCard />} />
        <Route path="/notes/create" element={<CreateNote />} />
        <Route path="/notes/edit/:id" element={<EditNote />} />
        <Route path="/:userId/tasks" element={<Tasks />} />
        <Route path="/tasks/:id" element={<ViewTask />} />
        <Route path="/tasks/create" element={<CreateTask />} />
        <Route path="/tasks/edit/:id" element={<EditTask />} />
        <Route path="/:userId/wallpapers" element={<Wallpapers />} />
        <Route path="/wallpapers/preview/:id" element={<ViewWallpaper />} />
        <Route path="/wallpapers/create" element={<CreateWallpaper />} />
        <Route path="/wallpapers/edit/:id" element={<EditWallpaper />} />
        <Route path="/users" element={<Users />} />
        <Route path="/:userId/notes" element={<Notes />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path='*' element={<Auth />} />
      </Routes>
    );
  }

  return (
    routes
  )
};

export default AppRoutes;