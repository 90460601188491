import { useState, useEffect, useContext} from "react";
import { useHttpClient } from 'hooks/http-hook';
import { AuthContext } from 'context/auth-context';
import SearchAndCreateBar from "components/SearchAndCreateBar/SearchAndCreateBar";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import './Homepage.scss';

const Homepage = (props) => {
	const auth = useContext(AuthContext);
	const { sendRequest, isLoading } = useHttpClient();
	const [offCanvasPanelVisible, setOffCanvasPanelVisible] = useState(null);
	const [randomWallpaper, setRandomWallpaper] = useState(null);

	// Get Random Wallpaper
	useEffect(() => {
		const fetchRandomWallpaper = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/wallpapers/${auth.userId}/random-wallpapers/1/${props.jesseAccountPublicViewMode}`
        );
        setRandomWallpaper(responseData.wallpapers[0]);
      } catch (err) {
        console.error('Error fetching random wallpaper:', err);
      }
    };
    fetchRandomWallpaper();
	}, [props.jesseAccountPublicViewMode, sendRequest, auth.userId]);

	// Toggles the Create Nav (with icons) Panel
	const toggleOffCanvasPanelVisibility= () => {
		setOffCanvasPanelVisible(!offCanvasPanelVisible);
	}

	if (isLoading) {
    return (
      <LoadingAnimation asOverlay />
    );
  }

	if (randomWallpaper === null) {
    return (
      <div className="homepage">
				<SearchAndCreateBar
					className="search-and-create-bar--black-transparent"
					onUse={toggleOffCanvasPanelVisibility}
				/>
			</div>
    );
  }

	let wallPaperStyles = {};

	if (randomWallpaper?.css?.length > 0) {
		const customStyle = JSON.parse(randomWallpaper?.css);
		wallPaperStyles = customStyle;
	}

	if (randomWallpaper?.image !== null) {
		wallPaperStyles = {...wallPaperStyles, backgroundImage: `url(${randomWallpaper?.image?.imageUrl})`};
	}

	return (
		<div className="homepage" style={wallPaperStyles}>
			<SearchAndCreateBar
				className="search-and-create-bar--black-transparent"
				onUse={toggleOffCanvasPanelVisibility}
				jesseAccountPublicViewMode={props.jesseAccountPublicViewMode}
			/>
		</div>
	);
};

export default Homepage;